import ApplicationFeatures from 'gw-portals-featuredetection-js/ApplicationFeatures';
import NavBarDirective from './directives/NavBarDirective';
import BillingSummaryTiles from './directives/BillingSummaryTiles';
import AccidentInfo_AND from './directives/policyContextSidebar_AND/accident-info_AND/AccidentInfo'; // ANDIE
import ContactUs_AND from './directives/policyContextSidebar_AND/contact-us_AND/ContactUs'; // ANDIE
import AccordionContentSection_AND from './directives/accordionContentSection_AND/AccordionContentSection'; // ANDIE
import NavigationMenu_AND from './directives/navigationMenu_AND/NavigationMenu'; // ANDIE
import PolicyList_AND from './directives/policyList_AND/PolicyList'; // ANDIE
import PncdHasBeenRemovedBanner_AND from './directives/PncdHasBeenRemovedBanner_AND/PncdHasBeenRemovedBanner';
import PolicyDocs_AND from './directives/policyDocs_AND/PolicyDocs'; // ANDIE
import RenewalBanner_AND from './directives/renewalBanner_AND/RenewalBanner'; // ANDIE
import ExpirationApproachingBanner_AND from './directives/expirationApproachingBanner_AND/ExpirationApproachingBanner'; // ANDIE
import PolicyHasExpiredBanner_AND from './directives/PolicyHasExpiredBanner_AND/PolicyHasExpiredBanner'; // ANDIE
import NonRenewalBanner_AND from './directives/nonRenewalBanner_AND/NonRenewalBanner'; // ANDIE
import AMPSubmissionDraftService from 'plugin?ampquote!portal/services/AMPSubmissionDraftService';
import CookieBanner_AND from  './directives/cookieBanner_AND/CookieBanner'; //ANDIE
import 'edge/platform/widgets/pagestructure/module';
import 'l10nModule';
import 'edge/billing/app';
import 'edge/policy/app';
import 'edge/endorsement/app';
import 'edge/usersprofile/app';
import 'gw-portals-enrollment-angular';
import 'edge/faq/app';
import 'edge/personalizedoffers/app';
import 'angularRouter';
import 'conditional?if=ampclaims&equals=true!edge/fnol/app';
import 'conditional?if=ampclaims&equals=true!edge/claim/app';
import 'edge/platform/widgets/mediasidebar/module';
import 'edge/platform/segmentation/module';
import 'conditional?if=journey&equals=true!edge/journey/app';
import ViewModelService from 'gw-portals-viewmodel-angular/ViewModelService';
import SetVersionDocumentTitle from 'gw-portals-platform-angular/versionName/SetVersionDocumentTitle';
import 'edge/platform/widgets/footer/module';
import 'edge/platform/uimetadata/module';
import 'gw-portals-driven-ui-angular/module';
import 'edge/policy/bonus-miles_AND/app'; // ANDIE
import 'edge/policy/your-insurance_AND/app'; // ANDIE
import 'edge/policy/your-renewal_AND/app'; // ANDIE
import 'edge/policy/top-up-miles_AND/app'; // ANDIE
import 'edge/policy/contact-us_AND/app'; // ANDIE
import 'edge/policy/user-account_AND/app'; // ANDIE
import 'edge/policy/telematics_AND/module'; // ANDIE
import 'edge/policy/rewards-shop_AND/app'; // ANDIE
import 'edge/policy/your-renewal_AND/app'; // ANDIE
import 'edge/quoteandbind/app'; // ANDIE
import 'edge/platform/widgets/modal/module';

import 'styles-portal/sass/modules.scss';
import ProductCodeUtil from './util/ProductCodeUtil';

const modules = [
    'ui.router',
    'edge.platform.widgets.pagestructure',
    'edge.platform.uimetadata',
    'edge.platform.metadataDrivenUI',
    'edge.platform.l10n',
    'edge.billing.app',
    'edge.policy.app',
    'edge.endorsement.app',
    'edge.usersprofile.app',
    'edge.enrollment.app',
    'edge.faq.app',
    'edge.platform.widgets.mediasidebar',
    'edge.platform.widgets.footer',
    'edge.platform.widgets.modal',
    'edge.and.bonus-miles.app', // ANDIE
    'edge.and.your-insurance.app', // ANDIE
    'edge.and.top-up-miles.app', // ANDIE
    'edge.and.contact-us.app', // ANDIE
    'edge.and.user-account.app', // ANDIE
    'edge.and.telematics', // ANDIE
    'edge.and.rewards-shop.app', // ANDIE
    'edge.quoteandbind.app',
    'edge.platform.segmentation', // ANDIE
    'edge.and.your-renewal.app' // ANDIE
];

const GTM_BRAND_ID = {
    'tbb': 'GTM-WBWMTQ4B',
    'itb': 'GTM-M9HCPR',
    'dgt': 'GTM-N7ZMKV',
    'toy': 'GTM-TDMPRPZ',
    'aut': 'GTM-PP9TWHS',
    'lex': 'GTM-K77PZDL',
    'kin': 'GTM-KDF69B2',
    'kfi': 'GTM-TFX9FBXT'
};

const BRANDS = [
    'tbb',
    'itb',
    'dgt',
    'toy',
    'lex',
    'aut',
    'kin',
    'kfi'
];

const LIVECHAT_BRAND_ID = {
    'tbb': 'XQMAk83v51bLOOZfhblTng%3d%3d',
    'itb': 'jdQLcaOYjl4pFjQbihDmew%3d%3d',
    'dgt': 'ec_bc27m7zXYEI1PSFssFdg%3d%3d',
    'aut': '5mQyAOGjIIzmVPi8FPwgyA%3d%3d',
    'lex': 'dzP7_bqTRXpFPS_fARj3uxtg%3d%3d',
    'toy': 'SNl3GrUkbMQ3mMBAiUeL6Q%3d%3d',
    'kin': 'YyknoTcfPQdA7Lxde_fNPYA%3d%3d',
    'kfi': 'mJYQhT_bCLdHxtKMO0U1UuA%3d%3d'
};

if (ApplicationFeatures.hasFeature('journey')) {
    modules.push('edge.journey.app');
}

if (ApplicationFeatures.hasFeature('personalizedoffers')) {
    modules.push('edge.personalizedoffers.app');
}

if (ApplicationFeatures.hasFeature('ampclaims')) {
    modules.push('edge.fnol.app');
    modules.push('edge.claim.app');
}

export default angular.module('portal.app', modules)
    .directive('billingSummaryTiles', BillingSummaryTiles)
    .directive('gwPortalNavBar', NavBarDirective)
    .directive('andAccidentInfo', AccidentInfo_AND) // ANDIE
    .directive('andContactUs', ContactUs_AND) // ANDIE
    .directive('andAccordionContentSection', AccordionContentSection_AND) // ANDIE
    .directive('andNavigationMenu', NavigationMenu_AND) // ANDIE
    .directive('andPolicyList', PolicyList_AND) // ANDIE
    .directive('andPolicyDocs', PolicyDocs_AND) // ANDIE
    .directive('andRenewalBanner', RenewalBanner_AND) // ANDIE
    .directive('andExpirationApproachingBanner', ExpirationApproachingBanner_AND) // ANDIE
    .directive('andPolicyHasExpiredBanner', PolicyHasExpiredBanner_AND)
    .directive('andNonRenewalBanner', NonRenewalBanner_AND) // ANDIE
    .directive('andPncdHasBeenRemovedBanner', PncdHasBeenRemovedBanner_AND)
    .directive('andCookieBanner', CookieBanner_AND) // ANDIE
    .factory('ViewModelService', ViewModelService)
    .factory('AMPSubmissionDraftService', AMPSubmissionDraftService)
    .factory('ProductCodeUtil', ProductCodeUtil)

    // ANDIE update the widget to show the correct page of policies
    .filter('startFrom_AND', () => {
        return function (input, start) {
            start = +start; // parse to int
            return input.slice(start);
        };
    })

    .config(['FaqServiceProvider', 'FAQ_CONFIG', (FaqServiceProvider, FAQ_CONFIG) => {
        FaqServiceProvider.setSections(FAQ_CONFIG.sections);
    }])

    .run(['$translate', 'brandingData', '$state', '$rootScope', ($translate, brandingData, $state, $rootScope) => {
        // ANDIE prevent OOTB default branding to be shown if no branding is specified
        if (!BRANDS.includes(brandingData)) {
            $state.go('');
        }

        $rootScope.productCode = ProductCodeUtil.getProductCode(brandingData)
        SetVersionDocumentTitle({
            $translate,
            // "portal.app.versionName.title[.${VERSION}]" l10n ID (with a version, if available for the app) will be used
            translationIdBase: `and.portal.app.versionName.title.${brandingData}` // ANDIE
        });
        // ANDIE favicon
        // document.head = document.head || document.getElementsByTagName('head')[0];
        const link = document.createElement('link');
        const oldLink = document.getElementById('and-dynamic-favicon');
        link.id = 'and-dynamic-favicon';
        link.rel = 'icon';
        link.href = `../../common/styles/images/favicon/favicon-${brandingData}.ico`;
        link.type = 'image/x-icon';
        if (oldLink) {
            document.head.removeChild(oldLink);
        }
        document.head.appendChild(link);
    }])

    .run(['brandingData', (brandingData) => {
        // ANDIE - IE/Edge .append() polyfill
        // https://developer.mozilla.org/en-US/docs/Web/API/ParentNode/append#Polyfill
        (function (arr) {
            arr.forEach((item) => {
                if (item.hasOwnProperty('append')) {
                    return;
                }
                Object.defineProperty(item, 'append', {
                    configurable: true,
                    enumerable: true,
                    writable: true,
                    value: function append() {
                        const argArr = Array.prototype.slice.call(arguments);
                        const docFrag = document.createDocumentFragment();
                        argArr.forEach((argItem) => {
                            const isNode = argItem instanceof Node;
                            docFrag.appendChild(isNode ? argItem : document.createTextNode(String(argItem)));
                        });
                        this.appendChild(docFrag);
                    }
                });
            });
        }([Element.prototype, Document.prototype, DocumentFragment.prototype]));

        // ANDIE Google TGM
        const gtm = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                            })(window,document,'script','dataLayer', '${GTM_BRAND_ID[brandingData]}');`;
        const gtmURL = `<iframe src='https://www.googletagmanager.com/ns.html?id=${GTM_BRAND_ID[brandingData]} height='0' width='0' style='display:none;visibility:hidden'></iframe>`;
        const script = document.createElement('script');
        script.append(gtm);
        document.head.insertBefore(script, document.head.firstChild);
        const noscript = document.createElement('noscript');
        document.body.insertBefore(noscript, document.body.firstChild);
        noscript.append(gtmURL);

        // ANDIE TBB Tagging
        if (brandingData === 'tbb') {
            const tbbTag = "https://nexus.ensighten.com/tescobank/Telematics/Bootstrap.js";
            const tbbTagScript = document.createElement('script');
            tbbTagScript.setAttribute('src', tbbTag);
            tbbTagScript.setAttribute('type', 'text/javascript');
            document.head.appendChild(tbbTagScript);
        }
    }])

    .run(['brandingData', (brandingData) => {
        const chatScript = document.createElement('script');
        const chatLogic = `BY.chat({
                            baseDomain: 'commbox.io/',                  
                            appsSubDomain: 'apps',
                            streamId:  '${LIVECHAT_BRAND_ID[brandingData]}',                  
                            trackerStreamId: 'isk0iIgqDx0aBC5T_fihKVA%3d%3d'
                        });`;
        chatScript.append(chatLogic);
        document.body.appendChild(chatScript);

    }]);
