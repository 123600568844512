import templateStr from 'text!./pm-ncd-protect.html';
import PNCDAppendix from 'text!./pncd-appendix.html';

export default ['$filter', ($filter) => {
    return {
        restrict: 'E',
        scope: {
            quoteData: '='
        },
        template: templateStr,

        controller: ['$scope', 'ModalService', 'brandingData', 'policy.PolicyService', ($scope, ModalService, brandingData, PolicyService) => {
            $scope.vehicle = $scope.quoteData.submission.lobData.personalMotor.value.coverables.pmVehicles[0];
            $scope.ncdYears = $scope.vehicle.ncdYears;
            const submission = $scope.quoteData.submission.value;
            $scope.ncdProtectionVM = {
                value: null,
                aspects: {
                    required: true,
                    availableValues: [true, false],
                    get valid() {
                        return angular.isDefined($scope.ncdProtectionVM.value) && $scope.ncdProtectionVM.value !== null;
                    },
                    get validationMessages() {
                        if (this.valid === false) {
                            return [$filter('translate')('displaykey.Edge.Web.Api.Model.NotNull')];
                        }
                        return [];
                    }
                }
            };

            if ($scope.vehicle.ncdProtection === true) {
                $scope.ncdProtectionVM.value = true;
            } else if ($scope.vehicle.ncdProtection === false && $scope.quoteData.submission.isBase_itb.value === true) {
                $scope.ncdProtectionVM.value = false;
            } else {
                $scope.ncdProtectionVM.value = null;
            }
            $scope.showPNCDAppendix = () => {
                ModalService.showSimpleDialog(PNCDAppendix, {
                    ncdYears: $scope.vehicle.ncdYears,
                    brand: brandingData.toLowerCase(),
                }, {}, null, {
                    fullscreen: true,
                    backdrop: true,
                    windowClass: 'and-modal'
                });
            };
            $scope.$watch('ncdProtectionVM.value', (newValue) => {
                if (newValue !== null && $scope.vehicle.ncdProtection !== newValue) {
                    $scope.quoteData.ncdProtection = newValue;
                    $scope.quoteData.updateQuote()
                        .then(() => { // Update current scope value
                            $scope.vehicle.ncdProtection = $scope.quoteData.ncdProtection;
                            // Changing the object reference isn't being picked up correctly here, so copying the amount
                            // directly.
                            if ($scope.vehicle.ncdCosts.amount === 0) { // only update the amount if previously null
                                $scope.vehicle.ncdCosts.amount = $scope.quoteData.ncdCosts.amount; 
                            }
                        });
                }
            });
            $scope.selectOption = (option, $event) => {
                if ($event.target.tagName === 'A') { // Ignore clicks to the document anchor links
                    return;
                }
                if (!$scope.quoteData.controlsDisabled()) {
                    $scope.ncdProtectionVM.value = option;
                }
            };
        }],

    };
}];